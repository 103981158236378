@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
    --primary : #027DBC;
    --secondary: #79CB43;
    --pure : #ffffff;
    --dark : #363636;
    --light : #F7F6F6;
}


html {
    scroll-behavior: smooth;
}

body {
    font-family: var(--font-fixel-display);
    background-color: var(--pure) !important;
    color : var(--dark) !important;
}

section {
    scroll-margin-top: 100px; /* Adjust as needed */
}


input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance: textfield;
}

::-webkit-scrollbar{
    width: 5px;
}
::-webkit-scrollbar-track{
    background-color: white;
}
::-webkit-scrollbar-thumb{
    background-color: gray;
}
::-webkit-scrollbar-thumb:hover{
    background-color: var(--primary);
}


/* React Confirm Alert */
.react-confirm-alert-overlay {
    background : #000000bd !important;
    z-index: 99999999 !important;
    
}
.react-confirm-alert-body {
    border-radius: 8px !important;
    background : var(--primary);
    color : var(--dark) !important;
    padding: 20px !important;
}
.react-confirm-alert-body h1 {
    @apply text-xl font-semibold mb-2 text-primary;
}
.react-confirm-alert-body button{
    @apply mt-4;
}
.react-confirm-alert-body button:first-child{
    @apply bg-primary hover:opacity-90;
}


/* SUPER_RESPONSIVE_TABLE */
.responsiveTable th:first-child {
    border-top-left-radius: 6px !important;
    border-bottom-left-radius: 6px !important;
}

.responsiveTable th:last-child {
    border-top-right-radius: 6px !important;
    border-bottom-right-radius: 6px !important;
}

@media (max-width: 640px) {
    .responsiveTable tr {
        border-radius: 10px !important;
        border-bottom: 4px solid var(--primary) !important;
        margin-bottom: 10px !important;
    }
}

/* React Tooltip */
.react-tooltip {
    @apply !text-[14px] !bg-primary !font-medium;
}