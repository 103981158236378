@tailwind utilities;

@layer utilities {
    .btn-white {
        @apply rounded-full bg-pure hover:scale-[1.1] duration-300 ease-in-out;
    }
    .font-clash {
        font-family: var(--font-clash-display);
    }   
    .w-spacing-sm {
        word-spacing: 0.1em;
    }
    .w-spacing-md {
        word-spacing: 0.2em;
    }
    .w-spacing-lg {
        word-spacing: 0.3em;
    }
    .text {
        @apply sm:text-base text-sm opacity-80;
    }     
    .shadow-circle {
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.15); 
    }


     /* ACTIONS BUTTONS */
     .btn-edit , .btn-delete {
        @apply w-[30px] h-[30px] rounded-full flex items-center justify-center text-pure text-base;
    }
    .btn-edit {
        @apply bg-green-500 hover:bg-opacity-80;
    }
    .btn-delete {
        @apply bg-red-500 hover:bg-opacity-80;
    }
}