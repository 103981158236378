.btn-primary {
    position: relative;
    transition: all 0.2s ease-in-out;
    /* box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2); */
    background-color: var(--primary);
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: #ffff;
    gap: 10px;
    font-weight: medium;
    outline: none;
    overflow: hidden;
}
.btn-primary:disabled , .btn-secondary:disabled {
    cursor : not-allowed;
}
.icon {
    width: 24px;
    height: 24px;
    transition: all 0.2s ease-in-out;
}

.btn-primary:hover {
    transform: scale(1.05);
}

.btn-primary:hover .icon {
    transform: translate(4px);
}

.btn-primary:hover::before {
    animation: shine 1.5s ease-out infinite;
}

.btn-primary::before {
    content: "";
    position: absolute;
    width: 100px;
    height: 100%;
    background-image: linear-gradient(
        120deg,
        rgba(255, 255, 255, 0) 30%,
        rgba(255, 255, 255, 0.8),
        rgba(255, 255, 255, 0) 70%
    );
    top: 0;
    left: -100px;
    opacity: 0.6;
}

@keyframes shine {
    0% {
        left: -100px;
    }

    60% {
        left: 100%;
    }

    to {
        left: 100%;
    }
}


/* BTN_SECONDARY */
/* From Uiverse.io by Madflows */ 
/* From Uiverse.io by mrhyddenn */ 
.btn-secondary {
    background: transparent;
    position: relative;
    display: flex;
    align-items: center;
    text-decoration: none;
    cursor: pointer;
    border: 2px solid var(--primary);
    border-radius: 8px;
    outline: none;
    overflow: hidden;
    color: var(--primary);
    transition: color 0.2s 0.1s ease-out;
    text-align: center;
}

  
.btn-secondary::before {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    content: '';
    border-radius: 50%;
    display: block;
    width: 20em;
    height: 20em;
    left: -5em;
    text-align: center;
    transition: box-shadow 0.4s ease-out;
    z-index: -1;
}
  
.btn-secondary:hover {
    color: #fff;
    border: 1px solid var(--primary);
}
  
.btn-secondary:hover::before {
    box-shadow: inset 0 0 0 10em var(--primary);
}
   
