.form-row {
    @apply flex flex-col gap-1.5 flex-1 w-full ;
}
.form-row label , .label {
    @apply font-semibold text-dark flex items-center gap-2 sm:text-base text-sm;
}
.form-row input , .form-row select , .form-row textarea , .input {
    @apply border border-gray-300 bg-pure py-3 px-3 text-dark rounded-md outline-none w-full placeholder:sm:text-base placeholder:text-sm sm:text-base text-sm;
}

.form-row input:focus-within , .form-row select:focus-within , .form-row textarea:focus-within , .input:focus-within {
    @apply border-primary;
}

.form-row .error-msg , .error-msg {
    @apply text-red-500 text-sm ;
}

.border-red {
    @apply border !border-red-500;
}
